@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400,400i,500,500i,600,600i,700,700i,800&display=swap);
@font-face {
  font-family: 'SundayMorning';
  src: local('SundayMorning'), url(/static/media/Sunday-Morning-v3.11f81eb7.woff) format('opentype');
  font-display: fallback;
}

html {
  scroll-behavior: smooth;
}

.backgroundFixed {
  /* background-size: 100vw; */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  -webkit-transform: scale(1);
          transform: scale(1);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1000;
}

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden scroll;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #feccd8;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100vw;
  overflow-x: hidden;
  position: absolute;
}

@media screen and (max-width: 1023px) {}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@-webkit-keyframes fadeInUp {
  0% {
    -webkit-transform: translate3d(0, 40px, 0);
            transform: translate3d(0, 40px, 0);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  0% {
    -webkit-transform: translate3d(0, 40px, 0);
            transform: translate3d(0, 40px, 0);
    opacity: 0;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animate p,
.animate a,
.animate h1,
.animate h2,
.animate h3,
.animate picture,
.animate .button,
.animate + .button{
  opacity: 0;
  -webkit-animation: fadeInUp 0.8s ease 0.05s forwards;
          animation: fadeInUp 0.8s ease 0.05s forwards;
  top: 0;
}
