@keyframes fadeInUp {
  0% {
    transform: translate3d(0, 40px, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.animate p,
.animate a,
.animate h1,
.animate h2,
.animate h3,
.animate picture,
.animate .button,
.animate + .button{
  opacity: 0;
  animation: fadeInUp 0.8s ease 0.05s forwards;
  top: 0;
}