@font-face {
  font-family: 'SundayMorning';
  src: local('SundayMorning'), url(./resources/fonts/Sunday-Morning-v3.woff) format('opentype');
  font-display: fallback;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,400i,500,500i,600,600i,700,700i,800&display=swap');

html {
  scroll-behavior: smooth;
}

.backgroundFixed {
  /* background-size: 100vw; */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  transform: scale(1);
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1000;
}

body {
  height: 100vh;
  width: 100vw;
  overflow: hidden scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #feccd8;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100vw;
  overflow-x: hidden;
  position: absolute;
}

@media screen and (max-width: 1023px) {}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}